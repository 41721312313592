<template>
  <div class="menuitems-main">    
      <div class="cat-menu-cls" :class="newCateClassAdd" v-if="is_menu_category && cateMenus && cateMenus.length > 0">      
        <nav class="category-menu-item-main px-0">
          <menu-category v-for="catmenu in cateMenus" :key=catmenu.id :menu="catmenu" v-on:setCategoryMenuId="openMenuCategoryList" :pagename="pageName"></menu-category>
        </nav>
      </div>
      
    <div class="menu-class-sticky">      
      <!-- <div class="side-bar" id="side-bar">
        <nav class="navigation pl-0" id="menulist">
          <ul class="mb-0">
            <li class="d-flex align-items-center">              
              <div v-if="menus.length == 0 && is_menu_skel_loader" class="skel-loader">
                <skeleton-loader-vue type="string" v-for="mn in 6" :key="mn" width="93px" height="29px" class="menuSkel" />
              </div>
              <template v-if="restautantDetails && $auth.setting && restautantDetails.item_counts >= $auth.setting.item_counts">
                <div v-for="menu in menus" :key="menu.restaurant_menu_id" >                  
                  <a href="javascript:void(0);" :id="'menuitem' + menu.restaurant_menu_id" class="rmvactv 45" :class="{ active : active_el == menu.restaurant_menu_id }" v-if="menu.item_counts != 0" @click="activeMenu(menu.restaurant_menu_id)">{{ menu.menu_name }}</a>
                </div>
              </template>
              <template v-if="restautantDetails && $auth.setting && restautantDetails.item_counts < $auth.setting.item_counts">
                <div v-if="recommendedList && recommendedList.length > 0" class="d-inline-flex">
                  <a href="javascript:void(0);" id="menuitem0" class="rmvactv 80" :class="{ active : active_el == 0 }"  @click="activeMenu(0)">{{$t('Recommended') }}</a>
                </div>               
                <div v-for="menu in filteredData" :key="menu.restaurant_menu_id">
                  <a href="javascript:void(0);" :id="'menuitem' + menu.restaurant_menu_id" class="rmvactv 88" :class="{ active : active_el == menu.restaurant_menu_id }" v-if="menu.item_counts != 0" @click="activeMenu(menu.restaurant_menu_id)">{{ menu.menu_name }}</a>
              </div>
              </template>
            </li>
          </ul>
        </nav>        
        <div class="searchbar-item" v-if="menus && menus.length > 0">
          <input type="search" v-model="search" name="search" class="srch-itm" :placeholder="$t('Search')">
        </div>
      </div> -->
      <div class="sidebar" id="side-bar" v-if="listItemVeg.length > 0 || restTags.length > 0">        
        <nav class="navigation extr-tags-list" id="menulist1">
          <ul class="mb-0 py-2">
            <li class="d-flex align-items-center">
              <div v-for="(list,i) in listItemVeg" class="tagslist">
                <a href="javascript:void(0);" :id="'menuitem' + i" class="rmvactv" :class="selectType.length > 0 && selectType.includes(list)?(list == '0'?'green':list == '1'?'red':''):''" v-if="list != 2" @click ="itemFilterClick(list,'oldtag')">{{(list == '0'?'Veg':list == '1'?'Non-veg':'')}}</a>
              </div>              
               <div v-for="(tag,index) in restTags" class="tagslist">
                <a href="javascript:void(0);"  :id="'menuitem' + tag.name" class="rmvactv" @click ="itemFilterClick(tag.name,'newtag')" :style="[selectType.includes(tag.name) ? {'background': '' + tag.background_color+'!important' + '','color': tag.text_color+'!important'}:'']">{{tag.name}}</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    
    </div>
    <div class="menuitems-main-wrapper d-xl-flex align-items-start">      
      <!-------nav bar start------->
      <div class="side-menu-main">
        <ul id="menulist">          
          <li v-if="recommendedList && recommendedList.length > 0">
            <a href="javascript:void(0)" id="menuitem0" class="rmvactv 80 side-menu-link" :class="{ active : (emptyMenuId != active_el)  }" @click="activeMenu(0)" >{{$t('Recommended') }}</a>
          </li>
          <li v-for="menu in filteredData" :key="menu.restaurant_menu_id">
            <a href="javascript:void(0)" :id="'menuitem' + menu.restaurant_menu_id" class="rmvactv 88 side-menu-link" :class="{ active : emptyMenuId == menu.restaurant_menu_id }" @click="activeMenu(menu.restaurant_menu_id)" v-if="menu.item_counts != 0">{{menu.menu_name }}</a>
          </li>
          <li v-for="mn in 6" :key="mn" v-if="menus.length == 0 && is_menu_skel_loader">
            <skeleton-loader-vue type="string" width="100%" height="29px" class="rmvactv 88 side-menu-link"/>
          </li>
        </ul>        
      </div>
      
      <!-------navbar end----------->            
      <div class="main-content w-100">
        <!-----skeleton Start layout-1---------->
        <div v-if="is_menu_skel_loader" class="menulength" id="itemmenumain">
          <section>
            <div class="media-heading">
              <h4 class="mb-4"><skeleton-loader-vue type="string" width="24%" height="20px"/></h4>
            </div>
            <div class="media-itms media-itms-theme5">
              <div class="item_detail noImgClass" right="true" v-for="prdt in 4" :key="prdt">
                <div class="media">
                  <a href="javascript:void(0)" id="element" class="btn btn-default show-modal">
                    <div class="media-body">
                      <h5 class="mt-0 me-5 item-name"><skeleton-loader-vue type="string" width="24%" height="20px"/></h5>
                      <div class="prc-show">
                        <span class="mt-2 d-inline-block item-price">
                          <skeleton-loader-vue type="string" width="54px" height="24px" />
                        </span>
                      </div>
                      <p class="mb-0 mt-2 item-descr"> <skeleton-loader-vue type="string" width="100%" height="20px" /> </p>
                    </div>
                  </a>
                  <div class="img-btn">
                    <div class="item-img itemimg-squre">
                      <skeleton-loader-vue width="120" height="120" class="img-cls" style="border-radius: 5px"/>
                    </div>
                    <div class="pgnme-cls btn-top">
                        <div class="nw-itm-btn">
                          <div class="sold-de">
                              <div class="icus"><button class="btn btn-sm"><skeleton-loader-vue type="string" width="50px" height="24px" /></button></div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>      
        <!-----skeleton End layout-1---------->
        <div v-if="menus && menus.length > 0 && menusdata.length ==  0" class="menulength" id="itemmenumain">
          <!--Recommanded Item start------>
          <div v-if="recommendedList.length > 0">
            <section id="item0">
              <div class="media-heading">
                <h4 class="mb-4">Recommended</h4>
              </div>
              <div class="media-itms" :class="'media-itms-theme'+((itemlayout==null || itemlayout == '')? '1':itemlayout)">                    
                <Product
                    v-for="item in recommendedList"
                    :key="item.item_id+item.restaurant_id"
                    :item="item"
                    :right="true"
                    menuId="0"
                    v-on:openProductDetailModal="openProductDeails"
                    :restaurantID="item.restaurant_id"
                    :restaurant_on_off="restaurant_on_off"
                    :class="item.item_id"
                    :pagename="pageName"
                    :itemlayout="(itemlayout==null || itemlayout == '')? '1':itemlayout"
                    :restautantDetails="restautantDetails"
                  ></Product>
                  <!-- :itemlayout="(itemlayout==null || itemlayout == '')? '1':itemlayout" -->
              </div>
            </section>
          </div>
          <!--Recommanded Item End------>

          <template v-for="menu in filteredData">
            <section :id="'item' + menu.restaurant_menu_id" v-if="menu.item_counts != 0" :ref="'item' + menu.restaurant_menu_id">
              <div class="media-heading">
                <h4 class="mb-4">{{ menu.menu_name }}</h4>
              </div>
              <div class="media-itms" :class="'media-itms-theme'+((itemlayout==null || itemlayout == '')? '1':itemlayout)">                    
                <Product
                    v-for="item in menu"
                    :key="itemlayout+'-'+item.item_id"
                    :item="item"
                    :right="true"
                    :menuId="menu.restaurant_menu_id"
                    v-on:openProductDetailModal="openProductDeails"
                    :restaurantID="item.restaurant_id"
                    :restaurant_on_off="restaurant_on_off"
                    :class="item.item_id"
                    :pagename="pageName"
                    :itemlayout="(itemlayout==null || itemlayout == '')? '1':itemlayout"
                    :restautantDetails="restautantDetails"
                  ></Product>
                  <!-- (itemlayout==null || itemlayout == '')? '1':itemlayout-->
              </div>
            </section>
          </template>
          
          <div v-if="Object.keys(filteredData).length === 0">
            <div class="no-txt-fnd ">
              <img src="@/assets/images/no_search.png" />
              <h6>{{$t('No Item Found')}}</h6>
              <span>{{$t('Please remove filters to see items.')}}</span>
            </div>
          </div>
        </div>
        <!---Category wise Menu item Open--->
        <div v-else-if="menusdata && menusdata.length != 0" class="menulength" id="itemmenumain 8">
          <div>
            <section :id="'item' + menusdata.restaurant_menu_id" v-if="menusdata.menu_item_detail != 0">
              <div class="media-heading">
                <h4 class="mb-4">{{ menusdata.menu_name }}</h4>
              </div>
              <div class="media-itms" :class="'media-itms-theme'+((itemlayout==null || itemlayout == '')? '1':itemlayout)">                    
                <Product
                    v-for="item in filteredItemData"
                    :key="itemlayout+'-'+item.item_id"
                    :item="item"
                    :right="true"
                    :menuId="menusdata.restaurant_menu_id"
                    v-on:openProductDetailModal="openProductDeails"
                    :restaurantID="item.restaurant_id"
                    :restaurant_on_off="restaurant_on_off"
                    :class="item.item_id"
                    :pagename="pageName"
                    :itemlayout="(itemlayout==null || itemlayout == '')? '1':itemlayout"
                    :restautantDetails="restautantDetails"
                  ></Product>
              </div>
            </section>
          </div>
            <div v-if="Object.keys(filteredItemData).length === 0">
            <div class="no-txt-fnd ">
              <img src="@/assets/images/no_search.png" />
              <h6>{{$t('No Item Found')}}</h6>
              <span>{{$t('Please remove filters to see items.')}}</span>
            </div>
          </div>
        </div>
        <!---Category wise Menu item Open--->
        <div v-else class="home-res-not-found">
          <div class="nostr-img-cls" v-if="!is_menu_skel_loader">
            <div class="no-txt-fnd">{{$t('No Item Found')}}</div>
          </div>
        </div>
      </div>
    </div>
    <ProductDetails ref="childComponent" :restaurantID="restaurantID" :restaurant_on_off="restaurant_on_off" :pagename="pageName"></ProductDetails>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import Product from "@/components/Product.vue";
import ProductDetails from "@/components/ProductDetails.vue";
import MenuCategory from "@/components/MenuCategory.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: { Product, ProductDetails, MenuCategory,'skeleton-loader-vue':VueSkeletonLoader },
  props:["restaurant_on_off","restautantDetails","search"],
  data() {
    return {
      menus: [],
      restaurantID: "",
      vendorID: "",
      date: "",
      starttime: "",
      productDetails: { type: Object },
      active_el:0,
      loading: true,
      // search:'',
      pageName:'itemslist',
      routerItemId:0,
      routerItemIdStatus: true,
      is_menu_category: false,
      cateMenus:[],
      menuCategryId : 0,
      is_menu_skel_loader: true,
      menusdata :[],
      isSearchHide : true,
      itemlayout:'1',
      newCateClassAdd : '',
      itemTypeFilterKey:'',
      itemType:[],
      typeUniqNumbers:[],
      selectType:[],
      listItemVeg:[],
      restTags:[],
      recommendedList:[],
      emptyMenuId:'',
      testArray:[
      {
        "restaurant_cate_id": 1000,
        "restaurant_id": 3700,
        "cate_name": "category-1",
        "cate_description": "this is first cate",
        "menu":
        [
          {
            "restaurant_menu_id": 57585,
            "restaurant_id": 3700,
            "color_code": null,
            "menu_name": "Dental Care",
            "name": "Dental Care",
            "menu_description": "",
            "is_display_image": "1",
            "menu_image": "",
            "image": "",
            "item_counts": 18,
            "menu_item_detail": [
              {
                "restaurant_id": 3700,
                "item_id": "1931653",
                "is_featured": "0",
                "item_name": "Closeup Complete ToothBrush Soft 1 Pc",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 999,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 6,
                "price": 6,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              },
              {
                "restaurant_id": 3700,
                "item_id": "1931654",
                "is_featured": "0",
                "item_name": "Colgate 360° Whole Mouth Clean Brush Medium With Tongue Cleaner    ",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 999,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 7,
                "price": 7,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              },
              {
                "restaurant_id": 3700,
                "item_id": "1931656",
                "is_featured": "0",
                "item_name": "Colgate Double Action Tooth Brush Medium",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124723078.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124723078.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 998,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 1,
                "price": 1,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              },
              {
                "restaurant_id": 3700,
                "item_id": "1931657",
                "is_featured": "0",
                "item_name": "Colgate Extra Clean Medium Tooth Brush",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124728269.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124728269.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 998,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 6,
                "price": 6,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              },
              {
                "restaurant_id": 3700,
                "item_id": "1931658",
                "is_featured": "0",
                "item_name": "Colgate Slim Soft Brush 0.01mm",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124739461.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124739461.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 998,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 6,
                "price": 6,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              }
              
            ]
          },
          {
            "restaurant_menu_id": 57585,
            "restaurant_id": 3700,
            "color_code": null,
            "menu_name": "Spicy Delights",
            "name": "Spicy Delights",
            "menu_description": "",
            "is_display_image": "1",
            "menu_image": "",
            "image": "",
            "item_counts": 18,
            "menu_item_detail": [
                {
                    "restaurant_menu_item_id": 2578826,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY PANEER",
                    "item_description": "Crispy and spicy paneer will spice up your taste quotient. Creamy sauce and crispy lettuce topping will sooth that extra spice",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 165,
                    "price": 165,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436357,
                            "restaurant_menu_item_id": 2578826,
                            "image_name": "deonde_menu_16704944294157.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:49.000000Z",
                            "updated_at": "2022-12-08T10:13:49.000000Z"
                        }
                    ],
                    "itemtags": []
                },
                {
                    "restaurant_menu_item_id": 2578827,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nPANEER WRAP",
                    "item_description": "Exquisitely picked, soft and tender paneer overwhelmed with a fiery, crunchy batter and dressed with fresh veggies & seasonings. Generously topped with creamy sauce and a dash of mustard & melted cheese that will surprise your tastes buds each time you sink your teeth into it.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 160,
                    "price": 160,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436358,
                            "restaurant_menu_item_id": 2578827,
                            "image_name": "deonde_menu_16704944299008.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:49.000000Z",
                            "updated_at": "2022-12-08T10:13:49.000000Z"
                        }
                    ],
                    "itemtags": []
                },
                {
                    "restaurant_menu_item_id": 2578828,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nCHICKEN WRAP",
                    "item_description": "Enjoy tender, juicy chicken coats with a hot & crispy batter and dressed with fresh salad consisting of lettuce, onions, tomatoes & seasonings. Further topped with delicious, creamy sauce and supple cheese slices that'll make your tongue tingle with joy.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 185,
                    "price": 185,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436359,
                            "restaurant_menu_item_id": 2578828,
                            "image_name": "deonde_menu_16704944295304.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:50.000000Z",
                            "updated_at": "2022-12-08T10:13:50.000000Z"
                        }
                    ],
                    "itemtags": []
                },
                {
                    "restaurant_menu_item_id": 2578829,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY CHICKEN",
                    "item_description": "Tender and juicy boneless chicken thigh meat coated in crispy batter with a kick of spice topped with a creamy sauce and crispy shredded lettuce will have you craving for more!",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 600,
                    "mrp": 180,
                    "price": 180,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436360,
                            "restaurant_menu_item_id": 2578829,
                            "image_name": "deonde_menu_16704944304220.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:50.000000Z",
                            "updated_at": "2022-12-08T10:13:50.000000Z"
                        }
                    ],
                    "itemtags": []
                }
                ]
            }
        ]
      },
      {
        "restaurant_cate_id": 1000,
        "restaurant_id": 3700,
        "cate_name": "category-2",
        "cate_description": "this is first cate",
        "menu":
        [
          {
            "restaurant_menu_id": 57585,
            "restaurant_id": 3700,
            "color_code": null,
            "menu_name": "Dental Care",
            "name": "Dental Care",
            "menu_description": "",
            "is_display_image": "1",
            "menu_image": "",
            "image": "",
            "item_counts": 18,
            "menu_item_detail": [
              {
                "restaurant_id": 3700,
                "item_id": "1931653",
                "is_featured": "0",
                "item_name": "Closeup Complete ToothBrush Soft 1 Pc",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124717.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 999,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 6,
                "price": 6,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              },
              {
                "restaurant_id": 3700,
                "item_id": "1931654",
                "is_featured": "0",
                "item_name": "Colgate 360° Whole Mouth Clean Brush Medium With Tongue Cleaner    ",
                "item_description": "Pack Size: 1 Piece",
                "image": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg",
                "item_images": [
                  {
                    "image_name": "https://storage.googleapis.com/deonde-prod/media/Restaurant_Menu_Item_image/40818/3700/deonde_menu_16385124726272.jpg"
                  }
                ],
                "item_tax": 0,
                "custom_details": [
                  
                ],
                "quantity": 999,
                "time_slot": "",
                "is_sold_out": "0",
                "mrp": 7,
                "price": 7,
                "is_alcoholic": "0",
                "item_type": "2",
                "item_packaging_charge": "0",
                "is_customization": 0,
                "item_tags": [
                  
                ]
              }
            ]
          },
          {
            "restaurant_menu_id": 57585,
            "restaurant_id": 3700,
            "color_code": null,
            "menu_name": "Spicy Delights",
            "name": "Spicy Delights",
            "menu_description": "",
            "is_display_image": "1",
            "menu_image": "",
            "image": "",
            "item_counts": 18,
            "menu_item_detail": [
                {
                    "restaurant_menu_item_id": 2578826,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "McSPICY PANEER",
                    "item_description": "Crispy and spicy paneer will spice up your taste quotient. Creamy sauce and crispy lettuce topping will sooth that extra spice",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 165,
                    "price": 165,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436357,
                            "restaurant_menu_item_id": 2578826,
                            "image_name": "deonde_menu_16704944294157.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:49.000000Z",
                            "updated_at": "2022-12-08T10:13:49.000000Z"
                        }
                    ],
                    "itemtags": []
                },
                {
                    "restaurant_menu_item_id": 2578827,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nPANEER WRAP",
                    "item_description": "Exquisitely picked, soft and tender paneer overwhelmed with a fiery, crunchy batter and dressed with fresh veggies & seasonings. Generously topped with creamy sauce and a dash of mustard & melted cheese that will surprise your tastes buds each time you sink your teeth into it.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 160,
                    "price": 160,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "0",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436358,
                            "restaurant_menu_item_id": 2578827,
                            "image_name": "deonde_menu_16704944299008.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:49.000000Z",
                            "updated_at": "2022-12-08T10:13:49.000000Z"
                        }
                    ],
                    "itemtags": []
                },
                {
                    "restaurant_menu_item_id": 2578828,
                    "restaurant_menu_id": 77708,
                    "is_featured": "0",
                    "item_name": "BIGSPICY \nCHICKEN WRAP",
                    "item_description": "Enjoy tender, juicy chicken coats with a hot & crispy batter and dressed with fresh salad consisting of lettuce, onions, tomatoes & seasonings. Further topped with delicious, creamy sauce and supple cheese slices that'll make your tongue tingle with joy.",
                    "item_name_thai": "",
                    "item_description_thai": "",
                    "taxslab": 0,
                    "quantity": 500,
                    "mrp": 185,
                    "price": 185,
                    "is_discount": 0,
                    "start_datetime": null,
                    "end_datetime": null,
                    "discount_percentage": null,
                    "item_type": "1",
                    "item_packaging_charge": "0",
                    "customise_count": 0,
                    "tax_slab": null,
                    "item_images": [
                        {
                            "id": 436359,
                            "restaurant_menu_item_id": 2578828,
                            "image_name": "deonde_menu_16704944295304.jpg",
                            "vendor_id": 41472,
                            "restaurant_id": 5118,
                            "created_at": "2022-12-08T10:13:50.000000Z",
                            "updated_at": "2022-12-08T10:13:50.000000Z"
                        }
                    ],
                    "itemtags": []
                }
                ]
            }
        ]
      }
    ]
  };
  },
  
  computed:{
    ...mapGetters("order", ["isPickupDelivery"]),
    
    filteredData() {
      this.recommendedList = [];      
      return Object.keys(this.menus).reduce((a, cKey) => {
        const data = this.filterValue(this.menus[cKey]);        
        if (data != undefined && data.length) {
          let menuName = this.menus[cKey].menu_name;          
          let nerecomm = data.find(itm => itm.is_featured == '1');
          if(nerecomm != undefined){
            this.recommendedList.push(nerecomm);            
          }
          a[cKey] = data;
          a[cKey].menu_name = menuName;
          a[cKey].item_counts = this.menus[cKey].item_counts;
          a[cKey].restaurant_menu_id = this.menus[cKey].restaurant_menu_id;          
        }
        
        if(this.selectType.length == 0){
          this.filterItemType();  
        }        
        return a;
      }, {}); 
      
    },
    
    filteredItemData() {      
      return Object.keys(this.menusdata).reduce((a, cKey) => {
        this.recommendedList = [];
        const data = this.filterValue(this.menusdata);
        if (data != undefined && data.length) {
          let menuName = this.menusdata.menu_name;
          a = data;
          a.menu_name = menuName;
          a.item_counts = 0;//this.menusdata.item_counts;
          a.restaurant_menu_id = this.menusdata.restaurant_menu_id;          
          data.forEach(item => {
            if((item.item_type == '0' || item.item_type == '1' || item.item_type == '2')){              
              this.itemType.push(item.item_type);
            }
          })          
        }        
        if(this.selectType.length == 0){
          this.filterItemType();  
        }
        return a;
      }, {});      
    },

    // filteredMenuData() {    
    //   return Object.keys(this.testArray).reduce((a, cKey) => {      
    //     let catename = this.testArray[cKey].cate_name;
    //     let menuArray = this.testArray[cKey].menu
    //       let ddd = Object.keys(menuArray).reduce((b, dKey) => {
    //         const data = this.filterValue(menuArray[dKey]);     
    //         if (data != undefined && data.length) {
    //           let menuName = menuArray[dKey].menu_name;              
    //           b[dKey] = data;
    //           b[dKey].menu_name = menuName;
    //           b[dKey].item_counts = menuArray[dKey].item_counts;
    //           b[dKey].restaurant_menu_id = menuArray[dKey].restaurant_menu_id;
    //         }
    //         if(this.selectType.length == 0){
    //           this.filterItemType();  
    //         }
    //         return b;
    //       }, {});
    //     a[cKey] = ddd;        
    //     a[cKey].categoryName = catename;
    //     return a;
    //   }, {}); 
      
    // },
  },

  mounted() {
    let routerHasValue = this.$route.hash;
    if (routerHasValue) {
      this.routerItemId = routerHasValue.replace('#item', '');
    }    
    this.vendorID = this.$auth.getVendorId();    
    this.restaurantID = this.restautantDetails && this.restautantDetails.restaurant_id;
    //this.itemlayout = this.$auth.setting && this.$auth.setting.restaurant_item_layout;
    this.itemlayout = this.restautantDetails && this.restautantDetails.restaurant_item_layout;
    if(this.itemlayout == ''){
      this.itemlayout = 1;
    }
    //this.filteredMenuData /* This function for multiple category menu and item display for loop */

    //Get All Products and Menu
    if(this.restautantDetails && this.$auth.setting && this.restautantDetails.item_counts > this.$auth.setting.item_counts){      
      this.isSearchHide = false;
      this.getCategoryWiseMenuList();
    }else{
      if(this.restautantDetails && this.restautantDetails.menu_category_count > 0 ){
        this.getCategoryWiseMenuList();
      }else{
        this.isSearchHide = true;
        this.getAllProducts();
      }
    }
    this.restTags = this.restautantDetails.restaurant_tags;
    // setTimeout(() => {
    //   if(document.querySelectorAll('.side-menu-link.active')){
    //     let actMenuId = document.querySelectorAll('.side-menu-link.active')[0].id;
    //     let menuScrollEl1 = document.getElementById(actMenuId);
    //     menuScrollEl1.scrollIntoView({behavior: "instant"});    
    //   }    
    // },2000);
  },
  created() {
    document.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {    
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions("product", ["getMenuProductList"]),
    ...mapActions("restaurant", ["getRestaurantMenuCategoryList","getMenuWiseItem","getCategoryWiseMenu"]),    

    filterItemType(){
      let uniqueType = [];
      if(this.itemType.length > 0){
        $.each(this.itemType, function(i, el){
          if($.inArray(el, uniqueType) === -1) uniqueType.push(el);
        });        
      }
      if(uniqueType.length == '1' && !uniqueType.includes('2')){
        this.listItemVeg = uniqueType;  
      }
      return uniqueType;
    },

    itemFilterClick(type,tag){
      if(this.selectType.indexOf(type) === -1){
        this.selectType.push(type)
      }else{          
        let newArray = this.selectType.filter(e => e !== type);          
        this.selectType = newArray;
      }
    },

    //Search Filter
    filterValue(content) {
      let itemProduct = content.menu_item_detail;
      if(itemProduct){
        return itemProduct.filter(item => {
        if(this.selectType.length > 0 ) {
            let newdata = this.selectType.findIndex(type => type === item.item_type);
            let newTagsSearch = this.selectType.findIndex(type => (item.item_tags.some(x=>x.name==type)));
            if(newdata != -1 || newTagsSearch != -1){
              return item;
            }
        }else{          
          let itemUpper = item.item_name.toLowerCase();
          let searchUpper = this.search.toLowerCase();          
          return itemUpper.indexOf(searchUpper) > -1;
        }
      });      
      }      
    },

    openMenuCategoryList(id){
      this.recommendedList = [];
      this.menus = [];
      this.menuCategryId = id;
      this.categoryMenuProduct();
    },

    getAllProducts() {      
      if(this.restautantDetails && this.restautantDetails.menu_category_count == 0){                
        this.is_menu_category = false;
        this.loading = true;
        this.getMenuProductList({
          date: "",
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          skip: 0,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
          total_record: 10000,
          cityname: "",
          latitude: localStorage.getItem("latitude"),
          delivery_type_time_slots: this.isPickupDelivery,
          menu_category_id: "",
          longitude: localStorage.getItem("longitude"),
        }).then((data) => {
          if (data.code == 200) {            
            this.loading = false;
            this.is_menu_skel_loader = false;
            this.menus = data.Result;
            let itemtypeArray = [];
            let isRecommendedProduct = '';
            this.menus.forEach(element => {
              if(element.menu_item_detail && element.menu_item_detail.length > 0){
                element.menu_item_detail.forEach(elm => {
                  itemtypeArray.push(elm.item_type);
                  if(elm.is_featured == '1') {
                    isRecommendedProduct = 'yes';
                  }
                });
              }
            });
            this.itemType = itemtypeArray;
            let findfirst = this.menus.find(el => el.item_counts > 0);
            if (isRecommendedProduct == '' ) {
              this.active_el = findfirst.restaurant_menu_id;
            }else{
              this.active_el = 0;
            }
            this.activeMenu(this.active_el);
            if(localStorage.getItem("menuCatID")){
              this.activeMenu(localStorage.getItem("menuCatID"));
            }
            if (this.routerItemId > 0) {
              setTimeout(() => this.activeMenu(this.routerItemId), 1200);
            }
          }else{
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
      }else if(this.restautantDetails && this.restautantDetails.menu_category_count > 0){
        if((this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0)){          
          this.menuCategryId = 0;         
          this.categoryMenuProduct();         
        }else{
          this.is_menu_category = true;
          this.getRestaurantMenuCategoryList({
            restaurant_id: this.restaurantID,
            vendor_id: this.vendorID,
            is_langauge: this.$store.state.lang.locale,
            latitude: localStorage.getItem("latitude"),
            longitude: localStorage.getItem("longitude"),
          }).then((data) => {
            if (data.code == 200) {
              this.is_menu_skel_loader = false;
              this.loading = false;
              this.cateMenus = data.Result;
              if(localStorage.getItem("menuCatID") == null || localStorage.getItem("menuCatID") == ''){
                if(this.cateMenus && this.cateMenus[0]){
                  this.menuCategryId = this.cateMenus[0].id;
                  this.categoryMenuProduct();
                }
              }else{
                this.is_menu_skel_loader = false;
                this.openMenuCategoryList(localStorage.getItem("menuCatID"));
                localStorage.setItem("menuCatID", '');
              }
            }else{
              this.is_menu_skel_loader = false;
              this.loading = false;
            }
          });
        }
      }
    },
    
    //Category On click to Get Product
    categoryMenuProduct(){
      this.loading = true;
      this.is_menu_skel_loader = true;
      this.getMenuProductList({
        date: "",
        restaurant_id: this.restaurantID,
        vendor_id: this.vendorID,
        skip: 0,
        starttime: "",
        is_langauge: this.$store.state.lang.locale,
        total_record: 1000,
        cityname: "",
        latitude: localStorage.getItem("latitude"),
        delivery_type_time_slots: this.isPickupDelivery,
        menu_category_id: this.menuCategryId,        
        longitude: localStorage.getItem("longitude"),
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.menus = data.Result;          
          let itemtypeArray = [];
          this.menus.forEach(element => {
            if(element.menu_item_detail && element.menu_item_detail.length > 0){
              element.menu_item_detail.forEach(elm => {
              itemtypeArray.push(elm.item_type);
            });
            }              
          });
          this.routerItemId = localStorage.getItem("menuCatID");
          this.itemType = itemtypeArray;
          let findfirst = this.menus.find(el => el.item_counts > 0);
          if (findfirst && this.recommendedList.length > 0) {
            this.active_el = findfirst.restaurant_menu_id;
          }          
          if (this.routerItemId > 0) {
            setTimeout(() => this.activeMenu(this.routerItemId), 1200);
          }
          let element = document.getElementById('menucateID-'+this.menuCategryId);
          $(".menucate-cls").removeClass("active");
          if(element != null){
            element.className += " active";
          }          
          this.is_menu_skel_loader = false;
        }else if(data.code == 101){
          this.menus = [];
          this.is_menu_skel_loader = false;
          this.loading = false;
        }else{
          this.is_menu_skel_loader = false;
          this.loading = false;
        }
      });
      
    },

    //ProductDetails Popup Modal Open
    openProductDeails(value) {
      window.$("#item-popup").modal("show");
      window.$("#item-popup").on('shown.bs.modal',function(){        
      });
      window.$("#item-popup").on('hidden.bs.modal',()=>{
        this.$refs.childComponent.productDetail = null
        this.$refs.childComponent.selectedCustomisation = {
          customize_type: {
          }
        }
      });
      this.$refs.childComponent.openProductModel(value.item_id);
    },
    
    // Active Menu
    activeMenu(itemId){
      let id = 'item'+itemId;       
      if(!this.isSearchHide){
        if(this.restautantDetails && this.restautantDetails.menu_category_count == 0){
          this.is_menu_skel_loader = true;
          this.menusdata = [];
          this.getMenuWiseItemList(itemId);
        }
        if(this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0){
          this.is_menu_skel_loader = true;
          this.menusdata = [];
          this.getMenuWiseItemList(itemId);
        }
      }
      setTimeout(() => {
        // Get current scroll position
        let scrollY = window.pageYOffset;
        let element = document.getElementById(id);
        if (element) {
          if (this.routerItemId > 0 && this.routerItemIdStatus) {
            const sectionHeight = element.offsetHeight;
            // const sectionTop = element.offsetTop - 80;
            let sectionId = element.getAttribute("id").replace('item', '');
            this.active_el = sectionId;
            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight){
              document.querySelector("#menuitem" + sectionId ).classList.add("active");
            } else {
              document.querySelector("#menuitem" + sectionId).classList.remove("active");
            }
            this.routerItemIdStatus = false;
            var menuScrollEl = document.getElementById('menuitem'+itemId);          
            menuScrollEl.scrollIntoView({behavior: "instant"});
            let y = element.getBoundingClientRect().top + window.pageYOffset - 100;            
            window.scrollTo({top: y, behavior: 'instant'});
          } else {
            let y = element.getBoundingClientRect().top + window.pageYOffset - 100 ;            
            window.scrollTo({top: y, behavior: 'instant'});
          }          
        }
        if(localStorage.getItem("menuCatID")){
          localStorage.setItem("menuCatID", '');
        }
      }, 100);      
    },

    //Category Wise Menu Display pagination.
    getCategoryWiseMenuList(){
      if(this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && this.restautantDetails.menu_category_count == 0){
        this.is_menu_category = false;
        this.loading = true;
        this.getCategoryWiseMenu({
          date: "",
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.is_menu_skel_loader = false;
            this.menus = data.Result;
            let findfirst = this.menus.find(el => el.item_counts > 0);
            if(localStorage.getItem("menuCatID")){
              this.getMenuWiseItemList(localStorage.getItem("menuCatID"));
            }else{
              if (findfirst) {
                this.active_el = findfirst.restaurant_menu_id;
                this.getMenuWiseItemList(findfirst.id);
              }
            }
            localStorage.setItem("menuCatID", '');
          }else{
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
      }else{
        if(this.restautantDetails && this.restautantDetails.menu_category_count == 0){
        this.is_menu_category = false;
        this.loading = true;
        this.getCategoryWiseMenu({
          date: "",
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          starttime: "",
          is_langauge: this.$store.state.lang.locale,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.is_menu_skel_loader = false;
            this.menus = data.Result;
            let findfirst = this.menus.find(el => el.item_counts > 0);
            if (findfirst) {
              this.active_el = findfirst.restaurant_menu_id;
              this.getMenuWiseItemList(findfirst.id);
            }
          }else{
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
        }else if(this.restautantDetails && this.restautantDetails.menu_category_count > 0){
        this.is_menu_category = true;
        this.getRestaurantMenuCategoryList({
          restaurant_id: this.restaurantID,
          vendor_id: this.vendorID,
          is_langauge: this.$store.state.lang.locale,
          latitude: localStorage.getItem("latitude"),
          longitude: localStorage.getItem("longitude"),
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            this.cateMenus = data.Result;
            this.newCateClassAdd = "cateNewClass";
            if(localStorage.getItem("menuCatID") == null || localStorage.getItem("menuCatID") == ''){
              if(this.cateMenus && this.cateMenus[0]){
                this.menuCategryId = this.cateMenus[0].id;
                this.categoryMenuProduct();
              }
            }else{
              this.openMenuCategoryList(localStorage.getItem("menuCatID"));
              localStorage.setItem("menuCatID", '');
            }
          }else{
            this.is_menu_skel_loader = false;
            this.loading = false;
          }
        });
        }
      }
    },

    //Menu Wise item Display pagination.
    getMenuWiseItemList(itemId){
      this.is_menu_category = false;
      this.loading = true;
      this.getMenuWiseItem({
        date: "",
        restaurant_id: this.restaurantID,
        vendor_id: this.vendorID,
        skip: 0,
        starttime: "",
        is_langauge: this.$store.state.lang.locale,
        total_record: 10000,
        menu_id: itemId,
      }).then((data) => {
        if (data.code == 200) {
          this.loading = false;
          this.is_menu_skel_loader = false;
          this.menusdata = data.Result[0];
        }else{
          this.loading = false;
          this.is_menu_skel_loader = false;
        }
      });
    },

    handleScroll() {
      // document.querySelectorAll("section[id^=item]").forEach((current) => {
      //   const el = document.getElementById(current.getAttribute('id'));
      //   if (this.elementInViewport(el)) {
      //     let scrollmenuid = current.getAttribute('id').replace('item', '');
      //     this.active_el = scrollmenuid;
      //     if (scrollmenuid) {            
      //       //this.active_el = scrollmenuid;
      //       let menuScrollEl = document.getElementById('menuitem'+scrollmenuid);
      //       if (menuScrollEl) {
      //         var scrollTo = window.$('#menuitem'+scrollmenuid).position().left;              
      //         document.getElementById('menulist').scrollLeft += scrollTo-10;
      //       }
      //     }
      //   }
      // });
        const sections = document.querySelectorAll("section[id]");
        // Get current scroll position
        let scrollY = window.pageYOffset;                
        // Now we loop through sections to get height, top and ID values for each
        sections.forEach(current => {
          const sectionHeight = current.offsetHeight;        
          const sectionTop = current.offsetTop - 125;
          let sectionId = current.getAttribute("id").replace('item', '');
          this.active_el = sectionId;          
          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight){                        
            this.emptyMenuId = '';
            document.querySelector("#menuitem" + sectionId ).classList.add("active");
            let menuScrollEl = document.getElementById('menuitem'+sectionId);            
            if (menuScrollEl) {
              var scrollTo = window.$('#menuitem'+sectionId).position().left;
              document.getElementById('menulist').scrollLeft += scrollTo;
            }
          } else {            
            if(this.emptyMenuId == ''){
              document.querySelector("#menuitem" + sectionId).classList.remove("active");            
              this.active_el = 0;              
            }            
            if(Object.keys(document.querySelectorAll('.side-menu-link.active')).length == 0){
              this.emptyMenuId = sections[0].getAttribute("id").replace('item', '');                            
            }
          }          
        });        
    },
  },

 
};
</script>

<style>
.menuSkel{
  border-radius: 30px !important;
}
.skel-loader{
  display: flex;
}
.sticcitem img {
  height: 30px;
  object-fit: contain;
}

</style>
